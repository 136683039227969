const routesConfig = {
  home: '/',
  signin: '/signin',
  verifyEmailMessage: '/verify-email',
  enrolmentForm: '/enrolment',
  signout: '/signout',
  signup: '/signup',
  signUpStudent: '/course/invite/:token',
  resetPassword: '/reset-password',
  resetPasswordVerify: '/reset-password/:token',
  emailVerification: '/email-verification/:token',
  course: '/class',
  profile: '/profile',
  profileDetails: '/profile/course/:courseId',
  diningRoom: '/profile/dining-room',
  viewClass: '/view-class/:course_id',
  lessonHistory: '/view-class/:course_id/history',
  lessonDetails: '/view-class/:course_id/history/:lessonId',
  lessonDetailsReview: '/view-class/:course_id/history/:lessonId/review',
  lessonDetailsRecording: '/view-class/:course_id/history/:lessonId/recording',
  lessonDetailsTeacherFeedback: '/view-class/:course_id/history/:lessonId/teacher-feedback',
  viewClassStudent: '/course/:courseId/student/:studentId',
  courseMaterial: '/view-class/:course_id/material',
  calendar: '/calendar',
  quiz: '/quiz',
  folder: '/quiz/folder/:id',
  quizEdit: '/quiz/edit/:id',
  meeting: '/class/:courseId/start',
  homework: '/homework',
  homeworkAssign: '/homework/assign',
  homeworkDetails: '/homework/:assignmentId',
  homeworkEdit: '/homework/:assignmentId/edit',
  submissionDetails: '/homework/:assignmentId/submission/:submissionId',
  homeworkEditSubmission: '/homework/:assignmentId/submission/:submissionId/edit',
  branding: '/branding',
  test: '/test',
  termsAndConditions: '/terms',
  funclassHomepage: 'https://www.getfunclass.com/general-4',
  bbbRedirect: '/bbb-redirect',
  adminStudentsDashboard: '/students',
  adminCoursesDashboard: '/courses',
  adminAddCourse: '/course/add',
  adminStudentProfile: '/student/:studentId',
  adminCourseDetails: '/course/:courseId',
  adminEditCourse: '/course/:courseId/edit',
  adminTeachersDashboard: '/teachers',
  adminTeacherProfile: '/teacher/:teacherId',
  myCalendar: '/my-calendar',
  adminLessonHistory: '/history',
  adminLessonDetails: '/course/:courseId/lesson/:lessonId',
  account: '/account',
  accountPolicy: '/account/policy',
  accountEdit: '/account/edit',
  accountUsage: '/account/usage',
  notFound: '/404',
  petSelection: '/pet-selection',
};

export default routesConfig;
